import * as React from 'react'
import { useEffect, useState, useLayoutEffect } from 'react'
import { navigate } from 'gatsby'
import Layout from '../../../components/Layout/Layout'
import { Navigation } from 'react-minimal-side-navigation'
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css'
import Highlight from 'react-highlight'
// markup
const More = () => {
  useLayoutEffect(() => {}, [])

  return (
    <Layout>
      <title>Consupedia - Api reference</title>
      {/* <div className="hero dark-bg alignfull">
        <div className="content-container">
          <div>
            <h1>Webhook</h1>
          </div>
        </div>
      </div> */}
      <section className="alignfull sidebar-page">
        <div className="nav">
          <>
            <Navigation
              // you can use your own router's api to get pathname
              activeItemId="/webhook/#about"
              onSelect={({ itemId }) => {
                // maybe push to the route
                navigate(itemId)
              }}
              items={[
                {
                  title: 'Rich subscriptions',
                  itemId: '/more'
                  // subNav: [
                  //   {
                  //     title: 'Get started',
                  //     itemId: '/api-reference/#api-reference'
                  //   },
                  //   {
                  //     title: 'Authorization',
                  //     itemId: '/api-reference/#authorization'
                  //   },
                  //   {
                  //     title: 'Connect API',
                  //     itemId: '/api-reference/#connect-api'
                  //   },
                  //   {
                  //     title: 'Product API',
                  //     itemId: '/api-reference/#product-api'
                  //   }
                  // ]
                }
              ]}
            />
          </>
        </div>
        <div className="section-container">
          <div id="api-reference">
            <span className="spacer" id="connect-api"></span>
            <h2>Complementary subscription endpoint for rich source data</h2>
            <p>
              This documentation is intended to complement the{' '}
              <a href="/v2/docs/">Connect API</a> documentations for clients
              with rich product subscriptions. For listing and deleting
              subscriptions, see <a href="/v2/docs/">main documentation</a> for
              details.
            </p>

            <span className="spacer" id="connect-api"></span>
            <h2>
              <b>
                <code>POST</code>
              </b>{' '}
              /subscribe/more/add
            </h2>

            <div>
              <p>
                <code>
                  https://connect.consupedia.com/api/v2/subscribe/more/add
                </code>
                <br />
                <br />
                <a href="/v2/docs/#tag/subscribe">
                  See the other endpoints under /subscribe here.
                </a>
              </p>

              <p>
                This endpoint enables the subscription of products. The request
                should have a JSON object in the body.
              </p>
              <h3 id="request-body">Request body</h3>
              <p>
                The JSON formatted body (Content-type application/json) should
                be a product represented as a single object.
              </p>
              <p>
                A product is required to have at least <code>gtin</code> or{' '}
                <code>client_identifier</code> field:
              </p>
              <Highlight>
                {`{
  "gtin": "123456789010",
}
`}
              </Highlight>
              <p>
                If you want to supply more product data, the following fields
                may also be added:
              </p>
              <Highlight>
                {`
    {
      "gtin": STRING,
      "client_identifier": STRING,
      "name": STRING,
      "information_provider_gln": STRING,
      "category": STRING,
      "subcategory": INTEGER,
      "brand_name": STRING,
      "external_name": STRING,
      "functional_name": STRING,
      "packaging_label": STRING,
      "country_of_origin": STRING,
      "provenance_statement": STRING,
      "raw_material_origin": STRING,
      "packaging_type": STRING,
      "packaging_material": STRING,
      "packaging_material_quantity": FLOAT,
      "packaging_material_unit": STRING,
      "ingredients": TEXT,
      "size": STRING,
      "alcohol_percentage": FLOAT,
      "preparation_state": STRING,
      "nutrient_basis_quantity": FLOAT,
      "nutrient_basis_unit": STRING,
      "quantity_energy_kj": FLOAT,
      "quantity_energy_kcal": FLOAT,
      "quantity_fat": FLOAT,
      "quantity_part_saturated_fat": FLOAT,
      "quantity_part_monounsaturated_fat": FLOAT,
      "quantity_part_polyunsaturated_fat": FLOAT,
      "quantity_carbohydrate": FLOAT,
      "quantity_part_sugars": FLOAT,
      "quantity_part_polyols": FLOAT,
      "quantity_part_starch": FLOAT,
      "quantity_fibre": FLOAT,
      "quantity_protein": FLOAT,
      "quantity_salt":FLOAT,
      "secondary_preparation_state": STRING,
      "secondary_nutrient_basis_quantity": FLOAT,
      "secondary_nutrient_basis_unit": STRING,
      "secondary_quantity_energy_kj": FLOAT,
      "secondary_quantity_energy_kcal": FLOAT,
      "secondary_quantity_fat": FLOAT,
      "secondary_quantity_part_saturated_fat": FLOAT,
      "secondary_quantity_part_monounsaturated_fat": FLOAT,
      "secondary_quantity_part_polyunsaturated_fat": FLOAT,
      "secondary_quantity_carbohydrate": FLOAT,
      "secondary_quantity_part_sugars": FLOAT,
      "secondary_quantity_part_polyols": FLOAT,
      "secondary_quantity_part_starch": FLOAT,
      "secondary_quantity_fibre": FLOAT,
      "secondary_quantity_protein": FLOAT,
      "secondary_quantity_salt":FLOAT,
      "marketing_message": TEXT,
      "image_original": STRING,
      "image_high_res": STRING,
      "image_low_res": STRING
    }`}
              </Highlight>

              <table>
                <thead>
                  <tr>
                    <th>Field Name</th>
                    <th>Description</th>
                    <th>Type</th>
                    <th>Max length</th>
                    <th>Example</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>gtin</td>
                    <td>Global Trade Item Number</td>
                    <td>STRING</td>
                    <td>14</td>
                    <td>12345678901234</td>
                  </tr>
                  <tr>
                    <td>client_identifier</td>
                    <td>Identifier assigned by the client</td>
                    <td>STRING</td>
                    <td>50</td>
                    <td>CI123456</td>
                  </tr>
                  <tr>
                    <td>name</td>
                    <td>Product name</td>
                    <td>STRING</td>
                    <td>255</td>
                    <td>Chocolate Bar</td>
                  </tr>
                  <tr>
                    <td>information_provider_gln</td>
                    <td>Global Location Number of the information provider</td>
                    <td>STRING</td>
                    <td>13</td>
                    <td>4000001000001</td>
                  </tr>
                  <tr>
                    <td>category</td>
                    <td>Product category</td>
                    <td>STRING</td>
                    <td>255</td>
                    <td>Alcoholic Beverages</td>
                  </tr>
                  <tr>
                    <td>subcategory</td>
                    <td>Product subcategory</td>
                    <td>INTEGER</td>
                    <td></td>
                    <td>10820</td>
                  </tr>
                  <tr>
                    <td>brand_name</td>
                    <td>Brand name of the product</td>
                    <td>STRING</td>
                    <td>100</td>
                    <td>Sweet Treats</td>
                  </tr>
                  <tr>
                    <td>external_name</td>
                    <td>Product name used in external systems</td>
                    <td>STRING</td>
                    <td>255</td>
                    <td>ChocoBar</td>
                  </tr>
                  <tr>
                    <td>functional_name</td>
                    <td>Functional name of the product</td>
                    <td>STRING</td>
                    <td>255</td>
                    <td>Snack</td>
                  </tr>
                  <tr>
                    <td>packaging_label</td>
                    <td>Packaging label information</td>
                    <td>STRING</td>
                    <td>255</td>
                    <td>Recyclable packaging</td>
                  </tr>
                  <tr>
                    <td>country_of_origin</td>
                    <td>Country where the product is originated</td>
                    <td>STRING</td>
                    <td>255</td>
                    <td>US</td>
                  </tr>
                  <tr>
                    <td>provenance_statement</td>
                    <td>Statement of product's provenance</td>
                    <td>STRING</td>
                    <td>255</td>
                    <td>Made in the USA</td>
                  </tr>
                  <tr>
                    <td>raw_material_origin</td>
                    <td>Origin of the raw materials</td>
                    <td>STRING</td>
                    <td>255</td>
                    <td>Cocoa beans from Ghana</td>
                  </tr>
                  <tr>
                    <td>packaging_type</td>
                    <td>Type of packaging</td>
                    <td>STRING</td>
                    <td>255</td>
                    <td>Box</td>
                  </tr>
                  <tr>
                    <td>packaging_material</td>
                    <td>Material used for packaging</td>
                    <td>STRING</td>
                    <td>255</td>
                    <td>Cardboard</td>
                  </tr>
                  <tr>
                    <td>packaging_material_quantity</td>
                    <td>Quantity of packaging material</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td>10.5</td>
                  </tr>
                  <tr>
                    <td>packaging_material_unit</td>
                    <td>Unit of measurement for packaging material</td>
                    <td>STRING</td>
                    <td>10</td>
                    <td>g</td>
                  </tr>
                  <tr>
                    <td>ingredients</td>
                    <td>List of ingredients</td>
                    <td>STRING</td>
                    <td>1000</td>
                    <td>
                      Cocoa mass, sugar, cocoa butter, emulsifier (soy
                      lecithin), natural vanilla flavor
                    </td>
                  </tr>
                  <tr>
                    <td>size</td>
                    <td>Product size</td>
                    <td>STRING</td>
                    <td>50</td>
                    <td>100g</td>
                  </tr>
                  <tr>
                    <td>alcohol_percentage</td>
                    <td>Alcohol percentage, if applicable</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td>0.0</td>
                  </tr>
                  <tr>
                    <td>preparation_state</td>
                    <td>State of the product before consumption</td>
                    <td>STRING</td>
                    <td>255</td>
                    <td>Ready-to-eat</td>
                  </tr>
                  <tr>
                    <td>nutrient_basis_quantity</td>
                    <td>Quantity for nutrient basis</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td>100.0</td>
                  </tr>
                  <tr>
                    <td>nutrient_basis_unit</td>
                    <td>Unit of measurement for nutrient basis</td>
                    <td>STRING</td>
                    <td>10</td>
                    <td>g</td>
                  </tr>
                  <tr>
                    <td>quantity_energy_kj</td>
                    <td>Energy in kilojoules</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td>2230.0</td>
                  </tr>
                  <tr>
                    <td>quantity_energy_kcal</td>
                    <td>Energy in kilocalories</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td>533.0</td>
                  </tr>
                  <tr>
                    <td>quantity_fat</td>
                    <td>Fat content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td>31.0</td>
                  </tr>
                  <tr>
                    <td>quantity_part_saturated_fat</td>
                    <td>Saturated fat content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td>0.3</td>
                  </tr>
                  <tr>
                    <td>quantity_part_monounsaturated_fat</td>
                    <td>Monounsaturated fat content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td>0.1</td>
                  </tr>
                  <tr>
                    <td>quantity_part_polyunsaturated_fat</td>
                    <td>Polyunsaturated fat content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td>4.0</td>
                  </tr>
                  <tr>
                    <td>quantity_carbohydrate</td>
                    <td>Carbohydrate content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td>51.0</td>
                  </tr>
                  <tr>
                    <td>quantity_part_sugars</td>
                    <td>Sugar content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td>47.0</td>
                  </tr>
                  <tr>
                    <td>quantity_part_polyols</td>
                    <td>Polyol content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td>0.0</td>
                  </tr>
                  <tr>
                    <td>quantity_part_starch</td>
                    <td>Starch content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td>4.0</td>
                  </tr>
                  <tr>
                    <td>quantity_fibre</td>
                    <td>Fibre content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td>3.0</td>
                  </tr>
                  <tr>
                    <td>quantity_protein</td>
                    <td>Protein content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td>7.0</td>
                  </tr>
                  <tr>
                    <td>quantity_salt</td>
                    <td>Salt content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td>0.1</td>
                  </tr>
                  <tr>
                    <td>secondary_preparation_state</td>
                    <td>Secondary state of the product before consumption</td>
                    <td>STRING</td>
                    <td>255</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>secondary_nutrient_basis_quantity</td>
                    <td>Secondary quantity for nutrient basis</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>secondary_nutrient_basis_unit</td>
                    <td>Secondary unit of measurement for nutrient basis</td>
                    <td>STRING</td>
                    <td>10</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>secondary_quantity_energy_kj</td>
                    <td>Secondary energy in kilojoules</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>secondary_quantity_energy_kcal</td>
                    <td>Secondary energy in kilocalories</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>secondary_quantity_fat</td>
                    <td>Secondary fat content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>secondary_quantity_part_saturated_fat</td>
                    <td>Secondary saturated fat content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>secondary_quantity_part_monounsaturated_fat</td>
                    <td>Secondary monounsaturated fat content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>secondary_quantity_part_polyunsaturated_fat</td>
                    <td>Secondary polyunsaturated fat content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>secondary_quantity_carbohydrate</td>
                    <td>Secondary carbohydrate content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>secondary_quantity_part_sugars</td>
                    <td>Secondary sugar content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>secondary_quantity_part_polyols</td>
                    <td>Secondary polyol content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>secondary_quantity_part_starch</td>
                    <td>Secondary starch content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>secondary_quantity_fibre</td>
                    <td>Secondary fibre content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>secondary_quantity_protein</td>
                    <td>Secondary protein content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>secondary_quantity_salt</td>
                    <td>Secondary salt content</td>
                    <td>FLOAT</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>marketing_message</td>
                    <td>Marketing message for the product</td>
                    <td>STRING</td>
                    <td>1000</td>
                    <td>
                      Indulge in the rich taste of our premium chocolate bar
                      made with the finest cocoa beans.
                    </td>
                  </tr>
                  <tr>
                    <td>image_original</td>
                    <td>URL of the original product image</td>
                    <td>STRING</td>
                    <td>500</td>
                    <td>
                      https://example.com/images/chocolate_bar_original.jpg
                    </td>
                  </tr>
                  <tr>
                    <td>image_high_res</td>
                    <td>URL of the high-resolution product image</td>
                    <td>STRING</td>
                    <td>500</td>
                    <td>
                      https://example.com/images/chocolate_bar_high_res.jpg
                    </td>
                  </tr>
                  <tr>
                    <td>image_low_res</td>
                    <td>URL of the low-resolution product image</td>
                    <td>STRING</td>
                    <td>500</td>
                    <td>
                      https://example.com/images/chocolate_bar_low_res.jpg
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2 id="supported-languages">Supported Languages</h2>
              <p>For best results, add text data in Swedish or English.</p>
              <h3 id="update-a-subscription-with-more-data">
                Update a subscription with more data
              </h3>
              <p>
                If you got more/changed product information and would like to
                update your subscription, use the same method and body as a new
                subscription. If the subscription already exists, the
                subscription will be updated.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="alignfull green-bg">
        <div className="section-container">
          <div>
            <h2>Support</h2>
            <p>You need any help with the Consupedia platform? Get in touch:</p>
            <p>
              <strong>
                <a href="mailto:tech-support@consupedia.com">
                  tech-support@consupedia.com
                </a>
              </strong>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default More
